import { Plan, Track } from '../@types'

const production = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

const rxOffers = [
  'Virtual Visit w/ Licensed Physician',
  'Unlimited messaging w/ Physician & Care Team',
  'Prescription for Weight Loss Medication',
  '1:1 Coaching w/ a Registered Dietitian',
  'Personalized Meal Plan w/ Grocery Lists',
]

const nutritionOffers = [
  '1:1 Coaching w/ a Registered Dietitian',
  'Personalized Meal Plan w/ Grocery Lists',
  'Access to Private Community',
]

export const behaviorPlans: Plan[] = [
  {
    id: 'PT2-1-MO-C',
    sku: 'PT2-1-MO-C',
    duration: 1,
    amount: 9900,
    name: '1 Month Plan',
    line_item: 'Measured Nutrition - 1 Month',
    billed: 'monthly',
    stripe_product_id: production
      ? 'prod_M7qpNjiNfeyktJ'
      : 'prod_LxIkgNJzt7ym8D',
    stripe_price_id: production
      ? 'price_1M4XaxBS2APHTgAqIQaulmPP'
      : 'price_1M4XVzBS2APHTgAqOBDIyQi4',
    deal: null,
    billed_long: 'Billed every month',
    originalPrice: 99,
    salePrice: 99,
    productName: '1 Month Plan',
    productNameLong: '1 Month Plan',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: null,
    showBonus: false,
    bonus: null,
    offers: nutritionOffers,
    track: Track.BEHAVIORAL,
  },
  {
    id: 'PT2-3-MO-C',
    sku: 'PT2-3-MO-C',
    duration: 3,
    originalPrice: 297,
    salePrice: 147,
    amount: 14700,
    name: '3 Month Plan',
    line_item: 'Measured Nutrition - 3 Months',
    billed: 'quarterly',
    stripe_product_id: production
      ? 'prod_M7qpNjiNfeyktJ'
      : 'prod_LxIkgNJzt7ym8D',
    stripe_price_id: production
      ? 'price_1M4XbHBS2APHTgAqVRIlP0c9'
      : 'price_1M4XWJBS2APHTgAqegiY1ism',
    deal: 'Limited Time Offer',
    billed_long: 'Billed every 3 months',
    productName: '3 Month Plan',
    productNameLong: '3 Month Plan',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: 'Special',
    showBonus: false,
    bonus: null,
    offers: nutritionOffers,
    track: Track.BEHAVIORAL,
  },
  {
    id: 'PT2-12-MO-C',
    sku: 'PT2-12-MO-C',
    duration: 12,
    originalPrice: 1188,
    salePrice: 228,
    amount: 22800,
    name: '12 Month Plan',
    line_item: 'Measured Nutrition - 12 Months',
    billed: 'annually',
    stripe_product_id: production
      ? 'prod_M7qpNjiNfeyktJ'
      : 'prod_LxIkgNJzt7ym8D',
    stripe_price_id: production
      ? 'price_1M4XcZBS2APHTgAqQVMdlbdP'
      : 'price_1M4XWbBS2APHTgAqyKjv0S30',
    deal: 'Best Deal',
    billed_long: 'Billed every year',
    productName: '12 Month Plan',
    productNameLong: '12 Month Plan',
    showMarkdownPrice: true,
    showSavedPercent: true,
    showSpecialOffer: 'Best Deal',
    showBonus: false,
    bonus: null,
    offers: nutritionOffers,
    track: Track.BEHAVIORAL,
  },
]
export const medicalPlans: Plan[] = [
  {
    id: 'PT2-1-MO-RX',
    sku: 'PT2-1-MO-RX',
    duration: 1,
    amount: 9900,
    name: '1 Month Plan',
    line_item: 'Measured Rx - 1 Month',
    billed: 'monthly',
    stripe_product_id: production
      ? 'prod_M7qsvnHYAVlcSk'
      : 'prod_LxIjQTNqtOxExC',
    stripe_price_id: production
      ? 'price_1M4Xd7BS2APHTgAq0Sn3Wc8q'
      : 'price_1M4XYNBS2APHTgAqbeITQQaz',
    deal: null,
    billed_long: 'Billed every month',
    originalPrice: 99,
    salePrice: 99,
    productName: '1 Month Plan (Rx)',
    productNameLong: '1 Month Plan (Rx)',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: null,
    showBonus: false,
    bonus: null,
    offers: rxOffers,
    track: Track.MEDICAL,
  },
  {
    id: 'PT2-3-MO-RX',
    duration: 3,
    originalPrice: 297,
    salePrice: 147,
    amount: 14700,
    name: '3 Month Plan',
    line_item: 'Measured Rx - 3 Months',
    billed: 'quarterly',
    stripe_product_id: production
      ? 'prod_M7qsvnHYAVlcSk'
      : 'prod_LxIjQTNqtOxExC',
    stripe_price_id: production
      ? 'price_1M4XdUBS2APHTgAqgQEtD4Xl'
      : 'price_1M4XYNBS2APHTgAqbeITQQaz',
    deal: 'Limited Time Offer',
    billed_long: 'Billed every 3 months',
    sku: 'PT1-3-MO-RX',
    productName: '3 Month Plan (Rx)',
    productNameLong: '3 Month Plan (Rx)',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: 'Special',
    showBonus: false,
    bonus: null,
    offers: rxOffers,
    track: Track.MEDICAL,
  },
  {
    id: 'PT2-12-MO-RX',
    duration: 12,
    originalPrice: 1188,
    salePrice: 228,
    amount: 22800,
    name: '12 Month Plan',
    line_item: 'Measured Rx - 12 Months',
    billed: 'annually',
    stripe_product_id: production
      ? 'prod_M7qsvnHYAVlcSk'
      : 'prod_LxIjQTNqtOxExC',
    stripe_price_id: production
      ? 'price_1M4XdtBS2APHTgAqR7bwYj6I'
      : 'price_1M4XZTBS2APHTgAq6iSse00q',
    deal: 'Best Deal',
    billed_long: 'Billed every 12 months',
    sku: '12-MO-RX',
    productName: '12 Month Plan (Rx)',
    productNameLong: '12 Month Plan (Rx)',
    showMarkdownPrice: true,
    showSavedPercent: true,
    showSpecialOffer: 'Best Deal',
    showBonus: false,
    bonus: null,
    offers: rxOffers,
    track: Track.MEDICAL,
  },
]

const plans = { medicalPlans, behaviorPlans }

export const defaultPlan = (track: Track) =>
  track === 'medical' ? plans.medicalPlans[2] : plans.behaviorPlans[2]

export default plans
